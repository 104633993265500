import Cookies from 'js-cookie';

import { isBlank } from './StringUtils';

class CookiesManager {
  constructor() {}
  /**
   * @returns {string | undefined}
   */
  getAccessToken() {
    const accessToken = Cookies.get('access_token');
    if (!isBlank(accessToken)) {
      return accessToken.replace('Bearer ', '');
    }
    const token = Cookies.get('token');
    if (!isBlank(token)) {
      return token.replace('Bearer ', '');
    }
    return undefined;
  }
  /**
   * @param {string?} accessToken
   * @returns {void}
   */
  setAccessToken(accessToken) {
    if (isBlank(accessToken)) return;
    Cookies.set('access_token', accessToken);
    Cookies.set('token', accessToken);
  }
}

export default new CookiesManager();
