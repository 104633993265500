import { useState, useEffect } from 'react';

/**
 * @param {Object} props
 * @param {number} props.wait
 * @param {JSX.Element} props.children
 * @param {string} props.className
 * @param {function} props.callback
 * @returns {JSX.Element?}
 */
const DelayContainer = ({ wait = 0, children, className, callback }) => {
  const [isShown, setIsShown] = useState(wait <= 0);
  useEffect(() => {
    if (wait <= 0) return;
    const timer = setTimeout(() => {
      setIsShown(true);
      callback();
    }, wait);
    return () => clearTimeout(timer);
  }, [wait, callback]);

  if (!isShown) return null;
  return <div className={className}>{children}</div>;
};

export default DelayContainer;
