import { useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';

import { isBlank } from '../utils/StringUtils';
import CookiesManager from '../utils/CookiesManager';
import BridgeManager from '../utils/BridgeManager';

const REQUEST_NEW_TOKEN = 'REQUEST_NEW_TOKEN';

/**
 * Refresh token 1 minute (in milliseconds) before expired
 */
const REFRESH_TOKEN_THRESHOLD = 60 * 1000;
/**
 * Check token every 10 seconds
 */
const CHECK_TOKEN_INTERVAL = 10 * 1000;

async function requestNewToken() {
  // Mobile refresh token
  BridgeManager.postMessage({ type: REQUEST_NEW_TOKEN });
  //   const refreshToken = CookiesManager.getRefreshToken();
  //   if (isBlank(refreshToken)) {
  //     return;
  //   }
}

async function checkToken() {
  const accessToken = CookiesManager.getAccessToken();
  if (isBlank(accessToken)) {
    await requestNewToken();
    return;
  }
  const decoded = jwtDecode(accessToken);
  if (decoded.exp === undefined) {
    await requestNewToken();
    return;
  }
  const tokenExpiredAt = new Date(decoded.exp * 1000 - REFRESH_TOKEN_THRESHOLD);
  const now = new Date();
  console.log('Checking token', now, tokenExpiredAt);
  if (now < tokenExpiredAt) return;
  await requestNewToken();
}

const useAutoRefreshToken = () => {
  useEffect(() => {
    checkToken();
    const intervalId = setInterval(checkToken, CHECK_TOKEN_INTERVAL);
    return () => {
      clearInterval(intervalId);
    };
  }, []);
};

export default useAutoRefreshToken;
