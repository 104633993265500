/**
 * @param {any[] | null | undefined} value
 * @returns {boolean}
 */
export function isEmpty(value) {
  return value === undefined || value === null || value.length <= 0;
}

/**
 * @param {any[] | null | undefined} value
 * @returns {boolean}
 */
export function isNotEmpty(value) {
  return !isEmpty(value);
}
