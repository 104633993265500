import { useState, useEffect } from 'react';
import EventBus from '@vertx/eventbus-bridge-client.js';

import { Config } from '../../../config/config';
import { CHATBOT_NAME } from '../../../utils/constants';
import CookiesManager from '../../../utils/CookiesManager';
import { camelCase, snakeCase } from '../../../utils/Transform';
import { isNotBlank } from '../../../utils/StringUtils';

import WebChatBox from './WebChatBox';
import MobileChatbox from './MobileChatbox';

import ChatService from '../../../services/ChatService';
import { CoachMessage } from '../../../models/CoachMessage';

const MESSAGES_PER_API_CALL = 20;

/**
 * Directly handles websocket/api logic and integrates it with ChatBox component
 * @component
 * @param {Object} props
 * @param {string} props.currentUser
 * @param {string} props.userToSendTo
 * @param {boolean} props.displayConnection
 * @param {boolean} props.isMobile
 * @param {string} props.token
 * @param {boolean} props.displayMobileSendMessage
 * @param {boolean} props.autoScrollBottom
 * @returns {JSX.Element}
 */
function SocketChatbox({
  currentUser,
  userToSendTo,
  displayConnection = true,
  isMobile = false,
  token,
  displayMobileSendMessage = false,
  autoScrollBottom = true,
}) {
  const address = `chat.${token}`;
  /** @type {[CoachMessage[], function]} */
  const [messageHistory, setMessageHistory] = useState([]);
  const [messageIndex, setMessageIndex] = useState(1);
  const [_, setLoading] = useState(false);
  const [eventBus, setEventBus] = useState(null);
  /** @type {[CoachMessage, function]} */
  const [lastMessage, setLastMessage] = useState(null);

  const enterMessage = (messageText) => {
    const message = {
      from: currentUser,
      to: userToSendTo || '',
      contents: [
        {
          type: 'text',
          message: messageText,
          timestamp: new Date().valueOf(),
        },
      ],
    };

    if (isNotBlank(messageText)) {
      sendJsonMessage(message);
      setMessageHistory([...messageHistory, message]);
    }
  };

  const fetchPreviousMessages = async () => {
    const pastMessages = await ChatService.MessagingResource.list(
      'nudge',
      currentUser,
      MESSAGES_PER_API_CALL,
      messageIndex,
    );
    if (pastMessages) {
      const messageArray = pastMessages;
      messageArray.reverse();
      if (messageArray.length !== 0) {
        const result = [...messageArray, ...messageHistory];
        setMessageIndex(messageIndex + 1);
        setMessageHistory(result);
      }
    }
  };

  useEffect(() => {
    fetchPreviousMessages();
  }, [currentUser]);

  //   const handleReceivedMessage = async (receivedMessage) => {
  //     if (receivedMessage && receivedMessage.data) {
  //       let jsonError = false;
  //       let jsonMessage = '';
  //       try {
  //         jsonMessage = JSON.parse(receivedMessage.data);
  //       } catch (e) {
  //         jsonError = true;
  //       }

  //       if (!jsonError) {
  //         if (jsonMessage.from !== CHATBOT_NAME) {
  //           setMessageHistory((prev) => prev.concat(jsonMessage));
  //         } else {
  //           // Will split contents into separate messages if message is from bot
  //           const jsonMessageContent = jsonMessage.contents;
  //           const result = [];
  //           jsonMessageContent.forEach(async (content) => {
  //             const newContentMessage = { ...jsonMessage, contents: [content] };
  //             result.push(newContentMessage);
  //           });
  //           setMessageHistory((prev) => prev.concat(...result));
  //         }
  //       }
  //     }
  //   };

  //   useEffect(() => {
  //     if (!isMobile) {
  //       handleReceivedMessage(lastMessage);
  //     }
  //   }, [lastMessage]);

  useEffect(() => {
    const eb = new EventBus(`${Config.API_URL}/messaging/chat`);
    eb.enableReconnect(true);
    eb.onopen = function () {
      eb.registerHandler(
        address,
        {
          token: token,
          user_id: currentUser,
          Authorization: 'Bearer ' + CookiesManager.getAccessToken(),
        },
        function (error, message) {
          // Adds notification to context
          const convertedMessage = new CoachMessage(camelCase(message.body));
          setLastMessage(convertedMessage);
        },
      );
    };
    eb.onerror = (err) => {
      console.error('EventBus encountered an error:', err);
    };
    setEventBus(eb);
    return () => {
      eb.close();
      setEventBus(null);
    };
  }, []);

  /**
   * Handles message scrolling for mobile and desktop chatboxes
   * @param {Element} e - Event from Message Container
   * @return {void}
   */
  const handleChatScroll = async (e) => {
    if (e.target.scrollTop === 0 && messageHistory.length !== 0) {
      const pastScroll = e.target.scrollHeight;
      await fetchPreviousMessages();
      // Element scroll height will change  when messages are fetched
      const currentScroll = e.target.scrollHeight - pastScroll;
      e.target.scrollTo(0, currentScroll);
    }
  };

  if (isMobile) {
    return (
      <MobileChatbox
        username={currentUser}
        oldMessages={messageHistory}
        sendJsonMessage={(message) => {
          eventBus?.send(address, snakeCase(message), {
            token: token,
            user_id: currentUser,
          });
        }}
        handleChatScroll={handleChatScroll}
        displayMobileSendMessage={displayMobileSendMessage}
        autoScrollBottom={autoScrollBottom}
        setLoading={setLoading}
        lastMessage={lastMessage}
        token={token}
      />
    );
  }
  return (
    <WebChatBox
      username={currentUser}
      onMessageSend={enterMessage}
      messages={messageHistory}
      connectionState={readyState}
      displayConnection={displayConnection}
      handleChatScroll={handleChatScroll}
    />
  );
}

export default SocketChatbox;
