import nutuApi from '../apis/nudgeApi';

export async function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export const MAX_RETRIES = 3;
export const DEFAULT_DELAY = 1500;

export class BaseService {
  /* @type {string} */
  _name;
  /* @type {string} */
  _path;
  /* @type {import('axios').AxiosInstance} */
  _client;
  /**
   * @param {string} name
   * @param {string} path
   */
  constructor(name, path) {
    this._name = name;
    this._path = path;
    this._client = nutuApi;
  }
  /**
   * @return {string} name
   */
  _getPath = () => {
    return this._name + this._path;
  };
}
