import { CoachMessage } from '../../../models/CoachMessage';
import { CoachContent } from '../../../models/CoachContent';
import { isEmpty, isNotEmpty } from '../../../utils/ArrayUtils';

export const sortMessages = (messages) => {
  messages.sort((x, y) => new Date(x.timestamp) - new Date(y.timestamp));
};

/**
 * Returns a new array that appends to and from properties to messages in content
 * @param {CoachMessage} message
 * @return {[CoachMessage & CoachContent]} - array of messages
 */
export const convertMessageContents = (message) => {
  const result = [];
  try {
    if (isEmpty(message.contents)) return result;
    message.contents.forEach((content) => {
      const { timestamp, type } = content;
      let convertedTimestamp =
        typeof timestamp === 'string' ? parseFloat(timestamp, 10) : timestamp;
      convertedTimestamp = type === 'dialog' ? convertedTimestamp * 1000 : convertedTimestamp;
      const messageValues = {
        ...message,
        ...content,
        timestamp: convertedTimestamp || message.createdAt,
      };
      result.push(messageValues);
    });
  } catch (e) {}
  return result;
};

export const scrollToBottomOfChatBox = async (e) => {
  if (e.current) {
    await e.current.scrollIntoView(true);
  }
};
