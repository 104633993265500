import { Auth } from 'aws-amplify';
import { isChatUrl } from '../utils/path';
import CookiesManager from '../utils/CookiesManager';

export function getAccessToken() {
  return new Promise(async (resolve, reject) => {
    try {
      if (isChatUrl()) {
        const token = CookiesManager.getAccessToken();
        resolve(token);
        return;
      }
      const res = await Auth.currentSession();
      const token = res.getAccessToken().getJwtToken();
      if (!token) console.warn('Access token is missing!');
      resolve(token);
    } catch (error) {
      reject(error);
    }
  });
}
