/**
 * @class CoachContent
 * @typedef {CoachContent}
 */
export class CoachContent {
  /** @type {string} */
  timestamp;

  /** @type {"text" | "dialog" | "image" | "multiple_images" | "location" | "hidden" | "redirect"} */
  type;

  /** @type {string?} */
  message;

  /** @type {string?} */
  title;

  /** @type {string?} */
  mediaType;

  /**
   * type = image
   * @type {string?}
   */
  url;

  /**
   * type = multiple_images
   * @type {string[]?}
   */
  urls;

  /**
   * type = location
   * @type {number?}
   */
  latitude;

  /**
   * type = location
   * @type {number?}
   */
  longitude;

  /**
   * type = text
   * @type {string?}
   */
  payload;

  /**
   * type = dialog
   * @type {string[]?}
   */
  buttons;

  /**
   * type = dialog
   * @type {string[]?}
   */
  payloads;

  /**
   * type = redirect
   * @type {boolean?}
   */
  redirect;

  constructor(options = {}) {
    Object.assign(this, options);
  }
}
