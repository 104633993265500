/**
 * @param {string | null | undefined} value
 * @returns {boolean}
 */
export function isBlank(value) {
  return value === undefined || value === null || value.trim() === '';
}

/**
 * @param {string | null | undefined} value
 * @returns {boolean}
 */
export function isNotBlank(value) {
  return !isBlank(value);
}

/**
 * Searches string for specified substring
 * @param {string} subString - String to find in text
 * @param {string} textToSearch - Text to locate substring from
 * @returns {boolean} - Indicates if substring is in text
 */
export const stringIsInText = (subString, textToSearch) => {
  const lowerCaseTextA = subString.toLowerCase();
  const lowerCaseTextB = textToSearch.toLowerCase();
  return lowerCaseTextB.includes(lowerCaseTextA);
};
