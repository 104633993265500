import { BaseService, sleep, MAX_RETRIES, DEFAULT_DELAY } from './BaseService';
import { camelCase } from '../utils/Transform';

import { CoachMessage } from '../models/CoachMessage';
import { ChatUser } from '../models/ChatUser';

class ChatService extends BaseService {
  /**
   * @param {string} path
   */
  constructor(path) {
    super('/messaging', path);
  }
}

class MessagingResource extends ChatService {
  constructor() {
    super('');
  }

  /**
   * @param {string} appId
   * @param {string} username
   * @param {string?} peer
   * @param {string?} topic
   * @returns {Promise<string?>}
   */
  create = async (appId, username, peer, topic) => {
    const params = {
      username: username,
    };
    if (peer) {
      params.peer = peer;
    }
    if (topic) {
      params.topic = topic;
    }
    const response = await this._client.get(this._getPath() + '/chat', {
      headers: { 'x-app-id': appId },
      params: params,
    });

    if (response && response.data) {
      const { message } = response.data;
      const splitMessage = message.split('/');
      const messageToken = splitMessage.pop();
      return messageToken;
    }

    return null;
  };

  /**
   * @param {string} appId
   * @param {string} user
   * @param {number} pageSize
   * @param {number} pageIndex
   * @returns {Promise<CoachMessage[]>}
   */
  list = async (appId, user, pageSize, pageIndex) => {
    const response = await this._client.get(this._getPath() + '/messages', {
      params: {
        app_id: appId,
        user: user,
        pageSize: pageSize,
        pageIndex: pageIndex,
      },
    });
    /** @type {any[]} */
    const data = camelCase(response?.data?.data) || [];
    return data.map((e) => new CoachMessage(e));
  };

  /**
   * @param {string} token
   * @returns {Promise<{user: ChatUser, peer: ChatUser} | {}>}
   */
  getUser = async (token) => {
    for (let i = 0; i < MAX_RETRIES; i++) {
      try {
        const response = await this._client.get(this._getPath() + `/chat/${token}/users`);
        if (response?.data?.code < 0) throw new Error('Network response was not ok');
        return camelCase(response?.data?.data);
      } catch (error) {
        if (i === MAX_RETRIES - 1) throw error;
        console.warn(`getUser Retrying... (${i + 1}/${MAX_RETRIES})`);
        await sleep(DEFAULT_DELAY);
      }
    }
  };
}

const service = {
  MessagingResource: new MessagingResource(),
};
export default service;
