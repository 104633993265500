import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import LinkifyText from './LinkifyText';

function MessageInfo({ timestamp, generatedByAi, displayQuestionButton, onDidNotAnswer }) {
  if (!timestamp) return null;
  const newDate = new Date(timestamp);
  let messageTimestamp;

  // checks if timestamp is in milliseconds or seconds
  if (Math.abs(Date.now() - newDate) < Math.abs(Date.now() - newDate * 1000)) {
    messageTimestamp = timestamp;
  } else {
    messageTimestamp = timestamp * 1000;
  }

  const messageDate = moment(messageTimestamp);
  const currentDate = moment();
  const diff = currentDate.diff(messageDate, 'days');
  const timeMessage =
    diff < 1
      ? moment(messageTimestamp).format('h:mm A')
      : `${diff} ${diff === 1 ? 'day' : 'days'} ago `;

  return (
    <div className="message-date-time">
      <p>
        {timeMessage}
        {generatedByAi && ' AI generated chat'}
      </p>
      {displayQuestionButton && (
        <a className="answer-button" onClick={onDidNotAnswer}>
          Escalate to Certified Coach
        </a>
      )}
    </div>
  );
}

function MessageBubble({
  className = '',
  textColor = 'intial',
  backgroundColor = '#e4e9ef',
  messageSender,
  nameTextColor = '#ACACAC',
  timestamp,
  alignRight = false,
  messageText,
  onClick,
  width,
  maxWidth = '100%',
  minHeight,
  isDialogResponse,
  height,
  children,
  generatedByAi = false,
  displayQuestionButton = false,
  isLatestMessage,
  onDidNotAnswer,
  messageType,
}) {
  return (
    <MessageBubbleContainer
      className={className}
      textColor={textColor}
      backgroundColor={backgroundColor}
      alignRight={alignRight}
      nameTextColor={nameTextColor}
      onClick={onClick}
      width={width}
      maxWidth={maxWidth}
      minHeight={minHeight}
      isDialogResponse={isDialogResponse}
      height={height}
    >
      {messageSender && <div className="message-sender-name">{messageSender}</div>}
      <div className="message-bubble-content">{(messageText && <LinkifyText text={messageText} />) || children}</div>
      <MessageInfo
        timestamp={timestamp}
        generatedByAi={generatedByAi}
        displayQuestionButton={displayQuestionButton}
        onDidNotAnswer={onDidNotAnswer}
      />
    </MessageBubbleContainer>
  );
}

const generateBorderRadius = (isDialogResponse, alignRight) => {
  if (isDialogResponse) {
    return '15px';
  } else if (alignRight) {
    return '15px 15px 0px 15px ';
  } else {
    return '15px 15px 15px 0px ';
  }
};

const MessageBubbleContainer = styled.div`
  width: ${(props) => (props.width ? props.width : 'max-content')};
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : '100%')};
  height: 100%;
  .message-sender-name {
    color: ${(props) => props.nameTextColor};
    font-weight: 600;
    font-size: 0.85rem;
    text-align: ${(props) => (props.alignRight ? 'right' : 'left')};
    text-transform: uppercase;
  }

  .message-bubble-content {
    width: 'fit-content';
    display: flex;
    font-size: 1rem;
    background-color: ${(props) => props.backgroundColor};
    padding: 11px 11px;
    border-radius: ${(props) => generateBorderRadius(props.isDialogResponse, props.alignRight)};
    color: ${(props) => props.textColor};
    word-break: break-word;
    white-space: pre-wrap;
    line-height: 20px;
    min-height: ${(props) => (props.minHeight ? props.minHeight : '')};
    justify-content: ${(props) => (props.isDialogResponse ? 'center' : '')};
    align-items: ${(props) => (props.isDialogResponse ? 'center' : '')};
    text-align: ${(props) => (props.isDialogResponse ? 'center' : '')};
    height: ${(props) => (props.height ? props.height : '100%')};
  }

  .message-date-time {
    color: #888b8f;
    font-weight: 600;
    font-size: 0.75rem;
    font-family: 'Roboto';

    p {
      /* font-family: 'Roboto'; */
      font-size: 8px;
      margin-bottom: 0px;
      color: #98abb9;
      text-align: ${(props) => (props.alignRight ? 'right' : 'left')};
    }

    .answer-button {
      text-decoration: underline;
      color: #c3c3c2;
      font-size: 12px;
    }
  }
`;

MessageBubble.propTypes = {
  textColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  messageSender: PropTypes.string,
  nameTextColor: PropTypes.string,
  timestamp: PropTypes.number,
  alignRight: PropTypes.bool,
  messageText: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  width: PropTypes.string,
  minHeight: PropTypes.string,
  isDialogResponse: PropTypes.bool,
};

export default MessageBubble;
