import { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import BridgeManager from '../../../utils/BridgeManager';
import { MESSAGE__TYPE, CHATBOT_NAME, AI_NAME } from '../../../utils/constants';
import { isNotEmpty } from '../../../utils/ArrayUtils';

import { scrollToBottomOfChatBox, convertMessageContents } from './chatBoxHelper';

import { CoachMessage } from '../../../models/CoachMessage';
import { CoachContent } from '../../../models/CoachContent';

import MessageBubble from './MessageBubble';
import DelayContainer from './DelayContainer';
import ChatInput from './ChatInput';

const DEPLAY_INCREMENT = 1000;

/**
 * @component
 * @param {Object} props
 * @param {string} props.username
 * @param {CoachMessage[]} props.oldMessages
 * @param {function (CoachMessage) : void } props.sendJsonMessage
 * @param {function} props.handleChatScroll
 * @param {boolean} props.displayMobileSendMessage
 * @param {boolean} props.autoScrollBottom
 * @param {function} props.setLoading
 * @param {CoachMessage} props.lastMessage
 * @param {string} props.token
 * @returns {JSX.Element}
 */
function MobileChatBox({
  username,
  oldMessages = [],
  sendJsonMessage,
  handleChatScroll,
  displayMobileSendMessage = true,
  autoScrollBottom = true,
  setLoading,
  lastMessage,
  token,
}) {
  const [messageText, setMessageText] = useState('');
  const [previousRecentMessage, setMostRecentMessage] = useState({ contents: [] });
  const endMessageRef = useRef(null);
  /** @type {[CoachMessage[], Function]} */
  const [newMessages, setNewMessages] = useState([]);
  const [displayMobileChatbox, _] = useState(displayMobileSendMessage);
  // Used to help determine when loading animation should load
  const [loadingMessage, setLoadingMessage] = useState(false);

  /**
   * @param {string} messageTosend
   */
  const enterMobileMessage = (messageTosend) => {
    if (messageTosend.trim() === '') return;
    const message = new CoachMessage();
    message.from = username;
    message.to = '';
    message.clientMessageId = uuidv4();
    message.contents = [
      new CoachContent({
        type: 'text',
        message: messageTosend,
        timestamp: new Date().valueOf(),
      }),
    ];
    sendJsonMessage(message);
    setNewMessages([...newMessages, message]);
  };

  /**
   * @param {CoachMessage} message
   */
  const handleReceivedMessage = async (message) => {
    if (!message || !message.messageId) return;
    // Handles redirect
    if (message.contents[0].type === MESSAGE__TYPE.REDIRECT) {
      setTimeout(() => {
        // Used to call Android/IOS API
        BridgeManager.postMessage({
          type: 'COACH_CHAT',
          returnUrl: `/chat/${token}`,
          deepLink: 'nutu://social/chat/coach?generateResponse=true',
        });
      }, 2000);
    }
    if (message.from !== CHATBOT_NAME) {
      message.timestamp = message.createdAt;
      setNewMessages((prev) => prev.concat(message));
    } else {
      const definedMessages = message.contents.filter(
        (content) => content.message !== undefined || content.title !== undefined,
      );
      setLoadingMessage(definedMessages.length > 0);
      // Time between messages
      let delayTime = DEPLAY_INCREMENT;
      // Will split contents into separate messages if message is from bot
      const result = [];
      if (isNotEmpty(message.contents)) {
        message.contents.forEach((content) => {
          const newContentMessage = {
            ...message,
            contents: [{ ...content, wait: delayTime }],
          };
          delayTime += DEPLAY_INCREMENT;
          result.push(newContentMessage);
        });
      }
      setNewMessages((prev) => prev.concat(...result));
    }
  };

  /**
   * @param {CoachMessage & CoachContent} message
   * @param {number} wait
   * @returns {JSX.Element}
   */
  const generateDialogResponses = (message, wait = 0) => {
    const result = [];
    message.buttons.forEach((button, index, buttons) => {
      if (!button) return;
      result.push(
        <DelayContainer
          key={`${message.messageId}_${message.clientMessageId}_${index}`}
          wait={wait}
          callback={() => {
            if (autoScrollBottom) {
              scrollToBottomOfChatBox(endMessageRef);
            }
            setLoading(false);
          }}
        >
          <MessageBubble
            messageText={button}
            backgroundColor="#5eb7c3"
            textColor="#FFFFFF"
            width="100%"
            minHeight={buttons.length === 1 ? '75px' : '61px'}
            isDialogResponse
            height={buttons.length === 1 ? '75px' : '100%'}
            onClick={async () => {
              setLoading(true);
              const optionSelected = new CoachMessage();
              optionSelected.from = username;
              optionSelected.messageTo = message.from;
              optionSelected.clientMessageId = uuidv4();
              optionSelected.contents = [
                new CoachContent({
                  type: 'text',
                  message: button,
                  timestamp: new Date().valueOf(),
                  payload: message.payloads[index],
                }),
              ];
              sendJsonMessage(optionSelected);
              result.pop();
              setNewMessages([...newMessages, optionSelected]);
            }}
          />
        </DelayContainer>,
      );
    });

    return (
      <div className={result.length > 1 ? 'dialog-responses' : 'dialog-response'}>{result}</div>
    );
  };

  /**
   * @param {[CoachMessage & CoachContent]} messages
   * @param {boolean} isNewMessage
   * @returns {JSX.Element}
   */
  const generateMessageTypes = (messages, isNewMessage = false) => {
    const result = [];
    messages.forEach((message, index, arr) => {
      const { wait } = message; // this one is from dialog
      const key = `${message.messageId}_${message.clientMessageId}`;
      const messageFromUser = message.from === username;
      if (
        (message.type === MESSAGE__TYPE.TEXT || message.type === MESSAGE__TYPE.REDIRECT) &&
        message.message !== undefined
      ) {
        result.push(
          <DelayContainer
            key={key}
            wait={wait}
            className={messageFromUser ? 'chatbox-messages right' : 'chatbox-messages'}
            callback={() => {
              if (autoScrollBottom) {
                scrollToBottomOfChatBox(endMessageRef);
              }
              if (message.from === CHATBOT_NAME && isNewMessage) {
                if (loadingMessage) {
                  setLoadingMessage(false);
                }
              }
            }}
          >
            <MessageBubble
              maxWidth="70%"
              messageText={message.message}
              alignRight={messageFromUser}
              generatedByAi={message.from === 'AI'}
              displayQuestionButton={
                message.from !== 'B' &&
                !messageFromUser &&
                message.type !== MESSAGE__TYPE.DIALOG &&
                message.type !== MESSAGE__TYPE.REDIRECT &&
                // Determines if current message is the most recent message sent
                JSON.stringify(message) === JSON.stringify(previousRecentMessage)
              }
              backgroundColor={messageFromUser ? '#5EB7C3' : '#FFFFFF'}
              textColor={messageFromUser ? '#FFFFFF' : '#425563'}
              timestamp={message.timestamp}
              messageType={message.type}
              onDidNotAnswer={() => {
                sendJsonMessage(
                  new CoachMessage({
                    from: username,
                    messageTo: AI_NAME,
                    clientMessageId: uuidv4(),
                    contents: [new CoachContent({ type: 'hidden', payload: '$0' })],
                  }),
                );
              }}
            />
          </DelayContainer>,
        );
      } else if (message.type === MESSAGE__TYPE.DIALOG) {
        result.push(
          <DelayContainer
            key={key}
            wait={wait}
            className="chatbox-messages"
            callback={() => {
              if (autoScrollBottom) {
                scrollToBottomOfChatBox(endMessageRef);
              }
              if (isNewMessage) {
                if (loadingMessage) {
                  setLoadingMessage(false);
                }
              }
            }}
          >
            <MessageBubble
              maxWidth="70%"
              messageText={message.title || message.message}
              timestamp={message.timestamp}
              alignRight={false}
              backgroundColor="#FFFFFF"
              textColor="#425563"
              generatedByAi={message.from === 'AI'}
              displayQuestionButton={message.from !== 'B' && message.type !== MESSAGE__TYPE.DIALOG}
            />
          </DelayContainer>,
        );

        if (index === arr.length - 1 && isNewMessage) {
          result.push(generateDialogResponses(message, wait));
        }
      }
    });

    return result;
  };

  /**
   * @param {CoachMessage[]} messages
   * @param {boolean} isNewMessage
   * @returns {JSX.Element}
   */
  const generateMessages = (messages, isNewMessage = false) => {
    if (messages.length === 0) return null;
    /** @type {[CoachMessage & CoachContent]} */
    const convertedMessages = [];
    messages.forEach((message) => {
      const convertedMessage = convertMessageContents(message);
      if (isNotEmpty(convertedMessage)) {
        convertedMessages.push(...convertedMessage);
      }
    });
    return generateMessageTypes(convertedMessages, isNewMessage);
  };

  useEffect(() => {
    // setLoading(true);
    window.__enterMobileMessage__ = enterMobileMessage;
  }, []);

  useEffect(() => {
    handleReceivedMessage(lastMessage);
  }, [lastMessage]);

  useEffect(() => {
    const latestMessage = oldMessages.length > 0 ? oldMessages[oldMessages.length - 1] : null;
    const mostRecentMessage =
      newMessages.length > 0 ? newMessages[newMessages.length - 1] : latestMessage;
    if (mostRecentMessage) {
      const convertedRecentMessage = convertMessageContents(mostRecentMessage);
      if (isNotEmpty(convertedRecentMessage)) {
        setMostRecentMessage(convertedRecentMessage[convertedRecentMessage.length - 1]);
      }
    }

    if (endMessageRef.current && oldMessages.length > 0 && autoScrollBottom) {
      scrollToBottomOfChatBox(endMessageRef);
    }
  }, [oldMessages, newMessages]);

  return (
    <Container>
      <ChatBoxContainer displayMobileChatbox={displayMobileChatbox}>
        <div className="message-container" onScroll={handleChatScroll}>
          {/* Generates previous messages before current session*/}
          {generateMessages(oldMessages)}
          {/* Generates messages in current session*/}
          {generateMessages(newMessages, true)}
          {loadingMessage && (
            <div className="chatbox-messages">
              <MessageBubble alignRight={false} backgroundColor="#FFFFFF" width="70px">
                <div className="dot-flashing mobile-chat" />
              </MessageBubble>
            </div>
          )}
          <div ref={endMessageRef} />
        </div>
        <div
          className={
            displayMobileChatbox
              ? 'mobile-chatbox-input-container'
              : 'mobile-chatbox-input-container-hidden'
          }
        >
          <div className="mobile-chatbox-inputs">
            <div className="mobile-chatbox-inputs__textarea-wrapper">
              <ChatInput messageText={messageText} setMessageText={setMessageText} />
            </div>
            {/* The empty p tag and click function is needed for mobile functionality */}
            <div className="chatbox-input-icons">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  enterMobileMessage(messageText);
                  setMessageText('');
                }}
                className="chatbox-send-button"
              >
                Send
              </button>
            </div>
          </div>
        </div>
      </ChatBoxContainer>
    </Container>
  );
}

const ChatBoxContainer = styled.div`
  height: 100%;
  background-color: #edece8;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  display: flex;
  flex-direction: column;

  .dot-flashing.mobile-chat {
    margin: auto;
  }

  .mobile-connection-error-text {
    color: red;
    margin: 5px 0px 0px 19px;
    font-size: 12px;
  }

  .companion-chatbox-name {
    display: flex;
    padding: 15px;
    align-items: center;

    > div {
      font-size: 18px;
      font-weight: bold;
      color: #425563;
    }
  }

  .mobile-chatbox-close {
    color: #5eb7c3;
    margin-right: 4%;
  }

  .chatbox-messages {
    margin-bottom: 12px;
  }

  .chatbox-messages.right {
    display: flex;
    justify-content: flex-end;
  }

  .chatbox-header {
    padding-left: 20px;
    position: fixed;
    bottom: 0;
    width: 100%;
  }

  .message-container {
    height: ${(props) => (props.displayMobileChatbox ? '95%' : '100%')};
    padding: 0px 20px 10px 20px;
    overflow-y: scroll;
    scroll-behavior: smooth;
  }

  .mobile-chatbox-input-container {
    /* position: fixed; */
    bottom: 0;
    width: 100%;
    background-color: #f7f8fc;
    max-height: 26vh;
    padding: 10px 0px;
    flex-grow: 1;
    display: flex;
  }

  .mobile-chatbox-input-container-hidden {
    visibility: hidden;
    height: 0;
    overflow: hidden;
  }

  .mobile-chatbox-inputs {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
  }
  .mobile-chatbox-inputs__textarea-wrapper {
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
  }

  .chat-textbox {
    resize: none;
    border: none;
    width: 100%;
    height: 35px;
    border-radius: 15px;
    padding: 5px 10px;
    margin-left: 3%;
    max-height: 24vh;
    padding-right: 2rem;
    outline: none;
  }

  .chat-textbox::placeholder {
    color: #c8d5de;
  }

  .chatbox-input-icons {
    width: 30%;
    margin-left: 5px;
    display: flex;
    justify-content: space-evenly;
  }

  .chatbox-icons {
    font-size: 25px;
    line-height: '25px';
    color: #dcdcdc;
  }

  .chatbox-icons:hover {
    cursor: pointer;
    color: grey;
  }

  .chatbox-emoji-picker {
    position: absolute;
  }

  .chatbox-send-button {
    min-width: 80%;
    height: 35px;
    border-radius: 5px;
    color: white;
    border: 1px solid #5eb7c3;
    background-color: #5eb7c3;
    outline: none;
  }

  .dialog-responses {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }

  .dialog-response {
    margin: auto;
    width: 170px;
  }
`;

const Container = styled.div`
  height: 100%;
  .connection-text {
    font-size: 12px;
  }
`;

export default MobileChatBox;
