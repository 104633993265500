export const CHATBOT_SERVER_URL = "https://mace.cercacor.com";

// note: we should move api to server, so these password won't be exposed.
// we put it here just for testing purpose

export const nutritionix = {
  appId: "1933af9c",
  appKey: "98a6e57d76f501087a39ed43fbf1cbb2",
};

export const edamam = {
  appId: "e9fcb0a5",
  appKey: "807d2bf9a5c21352fd5a1e04431c2315",

  recipeAppId: "d0833a32",
  recipeAppKey: "b84921eb4498df1f89e78ac1e09d9848",
};

const react_app_mapbox_token =
  "pk.eyJ1Ijoia2tvbWVzIiwiYSI6ImNrbmd4Ym13dTMxanYzMXBoeWY0aGpmdHkifQ.xF9Uca3TXdjuMPL1b_F7iw";

export const mapSource = {
  mapbox: {
    style: "mapbox://styles/mapbox/light-v9",
    apiKey: react_app_mapbox_token,
  },
  mapbox2: {
    style: "mapbox://styles/supaconducta2/ck8zj8h5w0fte1inwiri93yto",
    apiKey: react_app_mapbox_token,
  },
  opensource: {
    style: "https://openmaptiles.github.io/osm-bright-gl-style/style-cdn.json",
    apiKey: "",
  },
  none: {
    style: "/style.json",
    apiKey: "",
  },
};

export const unsplash = {
  url: "https://api.unsplash.com/search/photos",
  clientId: "AeVKVTSb0RLUwWGN12fiijtBGPX_CY87TzYxDu9A2zc",
};

export const Config = {
    API_URL: process.env.REACT_APP_API_URL || "https://guava-dev.ccc-jaguar-nug.co/api",
};

export default Config;
