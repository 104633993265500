import DOMPurify from 'dompurify';

function replaceURLsWithLinks(text) {
  const regex = /(\bhttps?:\/\/\S+)\s+\(\s*\1\s*\)/gi;
  return text?.replace(regex, '<a href="$1" target="_blank" rel="noopener noreferrer">$1</a>');
}

function LinkifyText({ text }) {
  // Configure DOMPurify to allow target="_blank" attribute
  DOMPurify.addHook('uponSanitizeAttribute', function (node, data) {
    if (data.attrName === 'target') {
      data.keepAttr = true; // Keep the attribute
    }
  });

  const sanitizedText = DOMPurify.sanitize(replaceURLsWithLinks(text), {
    ADD_ATTR: ['target'], // Add 'target' to the list of allowed attributes
  });

  return <div dangerouslySetInnerHTML={{ __html: sanitizedText }} />;
}

export default LinkifyText;
