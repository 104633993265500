import { useState, useEffect, useRef } from 'react';
import { Mic, MicNone } from '@material-ui/icons';
import styled from 'styled-components';

import BridgeManager from '../../../utils/BridgeManager';

const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;

/**
 * Speech recognition component that allows the user to interact with the microphone
 * and convert speech to text.
 *
 * @param {Object} props - The component props.
 * @param {Function} props.onSpeech - Callback function to handle the recognized speech.
 * @returns {JSX.Element} - The MicButton component.
 */
const MicButton = ({ onSpeech }) => {
  const [active, setActive] = useState(false);
  const recognitionRef = useRef(null);
  /**
   * Shows a dialog to request microphone permission.
   *
   * @returns {Promise<void>} - A Promise that resolves when the permission dialog is closed.
   * @throws {Error} - If permission to access the microphone is not granted.
   */
  const showPermissionDialog = async () => {
    // Asking Microphone permission
    await navigator?.mediaDevices?.getUserMedia({ audio: true });
    // Microphone permission granted
  };

  /**
   * Handles the speech recognition process.
   *
   * @returns {Promise<void>} - A Promise that resolves when the speech recognition process is complete.
   */
  const handleSpeech = async () => {
    try {
      // Toggling off mic if it is already on
      if (active) {
        recognitionRef.current?.abort();
        recognitionRef.current = null;
        setActive(false);
        return;
      }

      const recognition = recognitionRef.current || new SpeechRecognition();
      recognitionRef.current = recognition;

      recognition.continuous = false;
      recognition.lang = 'en-US';
      recognition.interimResults = true;
      recognition.maxAlternatives = 1;
      recognition.start();
      recognition.onstart = () => {
        setActive(true);
      };
      recognition.onresult = (event) => {
        const { transcript } = event.results[0][0];
        onSpeech?.(transcript);
      };

      // Deactivates the microphone when speech ends.
      recognition.onspeechend = () => {
        // Stop listening when speech ends in Chrome browser.
        // The onspeechend event stop action does not work correctly
        // in Safari iOS browsers. Checking for Chrome browser is necessary
        // to ensure this action is only performed for the Chrome engine
        // and not for Safari. This prevents the issue where the recognition instance
        // keeps listening but the microphone state is not active. On Safari iOS,
        // it is the user's responsibility to click the mic button to deactivate it.
        if (navigator.userAgent.indexOf('Chrome') === -1) {
          return;
        }
        recognition.stop();
        setActive(false);
      };

      recognition.onerror = (event) => {
        recognition.abort();
        if (event.error.toString() === 'not-allowed') {
          const dialogMessage =
            'This app needs access to your microphone. Please grant permission.';
          // Show the dialog
          alert(dialogMessage);
          showPermissionDialog();
        }
        setActive(false);
        BridgeManager.postErrorMessage({ message: event.error.toString() });
      };
    } catch (error) {
      console.error(error);
      BridgeManager.postErrorMessage({ message: error.message });
    }
  };

  // Cleaning up on component unmounting
  useEffect(() => {
    return () => {
      recognitionRef.current?.abort();
      recognitionRef.current = null;
    };
  }, []);

  // Do not show microphone if the SpeechRecognition engine is not available
  if (!SpeechRecognition) {
    return null;
  }
  return (
    <Button style={{ color: `${active ? 'red' : ''}` }} onClick={handleSpeech}>
      {active ? <Mic /> : <MicNone />}
    </Button>
  );
};

export default MicButton;

const Button = styled.button`
  position: absolute;
  padding: 0 0.5rem;
  right: 0;
  outline: none;
  border: none;
  background: none;
  color: #5eb7c3;
  &:focus {
    outline: none;
  }
`;
