import { CoachContent } from './CoachContent';

/**
 * @class CoachMessage
 * @typedef {CoachMessage}
 */
export class CoachMessage {
  /** @type {string} */
  messageId;

  /** @type {string} */
  from;

  /** @type {string} */
  fromName;

  /** @type {string} */
  messageTo;

  /** @type {string} */
  replyTo;

  /** @type {string} */
  sessionId;

  /** @type {CoachContent[]} */
  contents = [];

  /** @type {string} */
  targetedPeer;

  /** @type {string} */
  clientMessageId;

  /** @type {string} */
  status;

  /** @type {Date} */
  createdAt;

  constructor(options = {}) {
    Object.assign(this, options);
    if (this.contents && this.contents.length > 0) {
      this.contents = this.contents.map((e) => new CoachContent(e));
    }
  }
}
