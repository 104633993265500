import { useEffect } from 'react';

import CookiesManager from '../utils/CookiesManager';

const NEW_TOKEN = 'NEW_TOKEN';

const userNewTokenListener = () => {
  useEffect(() => {
    const handleNewToken = (event) => {
      if (event?.data?.type === NEW_TOKEN) {
        CookiesManager.setAccessToken(event?.data?.newToken);
      }
    };
    window?.addEventListener(NEW_TOKEN, handleNewToken);
    return () => {
      window?.removeEventListener(NEW_TOKEN, handleNewToken);
    };
  }, []);
};

export default userNewTokenListener;
