import axios from 'axios';
import { getAccessToken } from '../services/token';
import { Config } from '../config/config';
import { snakeCase } from '../utils/Transform';

const nudgeApi = axios.create({
  baseURL: Config.API_URL,
});

nudgeApi.interceptors.request.use(async function (config) {
  const accessToken = await getAccessToken();
  config.headers.Authorization = 'Bearer ' + accessToken;
  // POST, PUT, PATCH, DELETE only
  if (config.data && !(config.data instanceof FormData)) {
    config.data = snakeCase(config.data);
  }
  return config;
});

export default nudgeApi;
