import { useEffect, useState } from 'react';
import { SocketChatbox } from '../../components/common/ChatBox';

import ChatService from '../../services/ChatService';

function getChatSessionToken() {
  const splitPathName = window.location.pathname.split('/');
  return splitPathName[2];
}
function getHideInput() {
  const queryParameters = new URLSearchParams(window.location.search);
  const urlInputValue = queryParameters.get('hideInput');
  return urlInputValue === 'true';
}

function MobileChatPage() {
  const [userInfo, setUserInfo] = useState(null);

  const hideInput = getHideInput();
  const token = getChatSessionToken();

  useEffect(() => {
    const getMobileInfo = async () => {
      const users = await ChatService.MessagingResource.getUser(token);
      if (users) {
        setUserInfo(users.user || {});
      }
    };
    getMobileInfo();
  }, [token]);

  if (userInfo === null) {
    return (
      <div className="h-screen w-screen flex align-items-center justify-content-center">
        <div className="text-center">Loading ...</div>
      </div>
    );
  }
  if (Object.keys(userInfo).length === 0) {
    return (
      <div className="h-screen w-screen flex align-items-center justify-content-center">
        <div className="text-center">404 Not Found</div>
      </div>
    );
  }
  return (
    <div className="h-screen w-screen">
      <SocketChatbox
        currentUser={userInfo.userId}
        userToSendTo="B"
        isMobile
        token={token}
        displayMobileSendMessage={!hideInput}
        autoScrollBottom
      />
    </div>
  );
}

export default MobileChatPage;
