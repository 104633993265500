import React, { Suspense, lazy, Children } from 'react';
import { Route } from 'react-router-dom';
import { MenuItem } from '../restaurant/cuisine/MenuItem';
import { FOOD_TYPES } from '../../utils/constants';

/**
 * NOTE: Please add new routes here by lazy loading instead of direct imports in order to reduce
 * bundle size.
 */

const Dashboard = lazy(() => import('../dashboard/Dashboard'));
const Staffs = lazy(() => import('../staffs/Staffs'));
const EditStaff = lazy(() => import('../staffs/EditStaff'));
const Users = lazy(() => import('../users/Users'));
const UserView = lazy(() => import('../users/UserView'));
const UserAdd = lazy(() => import('../users/UserAdd'));
const UserEdit = lazy(() => import('../users/UserEdit'));
const SmartGoalsLookup = lazy(() => import('../smartGoal/smartGoalsLookup/SmartGoalsLookup'));
const SmartGoalsLookupAdd = lazy(() => import('../smartGoal/smartGoalsLookup/SmartGoalsLookupAdd'));
const SmartGoalsLookupEdit = lazy(() =>
  import('../smartGoal/smartGoalsLookup/SmartGoalsLookupEdit'),
);

const SmartGoals = lazy(() => import('../smartGoal/goal/SmartGoals'));
const SmartGoalAdd = lazy(() => import('../smartGoal/goal/SmartGoalAdd'));
const SmartGoalEdit = lazy(() => import('../smartGoal/goal/SmartGoalEdit'));
const DailyTasksLookup = lazy(() => import('../smartGoal/dailyTaskLookup/DailyTasksLookup'));
const DailyTasksLookupAdd = lazy(() => import('../smartGoal/dailyTaskLookup/DailyTasksLookupAdd'));
const DailyTasksLookupEdit = lazy(() =>
  import('../smartGoal/dailyTaskLookup/DailyTasksLookupEdit'),
);

const DailyTasks = lazy(() => import('../smartGoal/dailyTask/DailyTasks'));
const DailyTaskAdd = lazy(() => import('../smartGoal/dailyTask/DailyTaskAdd'));
const DailyTaskEdit = lazy(() => import('../smartGoal/dailyTask/DailyTaskEdit'));
const Tasks = lazy(() => import('../smartGoal/task/Tasks'));
const TaskAdd = lazy(() => import('../smartGoal/task/TaskAdd'));
const TaskEdit = lazy(() => import('../smartGoal/task/TaskEdit'));

const Chatbots = lazy(() => import('../smartGoal/chatbot/Chatbots'));
const ChatbotAdd = lazy(() => import('../smartGoal/chatbot/ChatbotAdd'));
const ChatbotEdit = lazy(() => import('../smartGoal/chatbot/ChatbotEdit'));
const Medias = lazy(() => import('../smartGoal/media/Medias'));
const MediaAdd = lazy(() => import('../smartGoal/media/MediaAdd'));
const MediaEdit = lazy(() => import('../smartGoal/media/MediaEdit'));

const StoreTypes = lazy(() => import('../grocery/storeType/StoreTypes'));
const StoreTypeAdd = lazy(() => import('../grocery/storeType/StoreTypeAdd'));
const StoreTypeEdit = lazy(() => import('../grocery/storeType/StoreTypeEdit'));
const HomeSnacks = lazy(() => import('../homeMeal/homeSnacks/HomeSnacks'));
const HomeSnackForm = lazy(() => import('../homeMeal/homeSnacks/HomeSnackForm'));
const CuisineCategories = lazy(() => import('../restaurant/cuisine/CuisineCategories'));

const CuisineCategoryAdd = lazy(() => import('../restaurant/cuisine/CuisineCategoryAdd'));
const CuisineCategoryEdit = lazy(() => import('../restaurant/cuisine/CuisineCategoryEdit'));
const CuisineMain = lazy(() => import('../restaurant/cuisine/CuisineMain'));
const CuisineRestaurants = lazy(() => import('../restaurant/cuisine/CuisineRestaurants'));
const CuisineRestaurantAdd = lazy(() => import('../restaurant/cuisine/CuisineRestaurantAdd'));
const CuisineRestaurantEdit = lazy(() => import('../restaurant/cuisine/CuisineRestaurantEdit'));

const HomeMealRecipes = lazy(() => import('../homeMeal/recipes/Recipes'));
const HomeMealRecipeForm = lazy(() => import('../homeMeal/recipes/RecipeForm'));
const HomeMealFoods = lazy(() => import('../homeMeal/foods/Foods'));
const HomeMealFoodForm = lazy(() => import('../homeMeal/foods/FoodForm'));
const HomeMealFoodList = lazy(() => import('../homeMeal/foods/FoodList'));

const HomeMealFoodCategory = lazy(() => import('../homeMeal/foods/FoodCategory'));
const RestaurantMenu = lazy(() => import('../restaurant/cuisine/RestaurantMenu'));
const MenuAddonItem = lazy(() => import('../restaurant/cuisine/MenuAddonItem'));
const RestaurantForm = lazy(() => import('../restaurant/cuisine/RestaurantForm'));
const MenuDishType = lazy(() => import('../restaurant/cuisine/MenuDishType'));

const SnackShops = lazy(() => import('../restaurant/snackShop/SnackShops'));
const SnackShopAdd = lazy(() => import('../restaurant/snackShop/SnackShopAdd'));
const SnackShopEdit = lazy(() => import('../restaurant/snackShop/SnackShopEdit'));
const SnackShopRestaurants = lazy(() => import('../restaurant/snackShop/SnackShopRestaurants'));
const SnackShopRestaurantForm = lazy(() =>
  import('../restaurant/snackShop/SnackShopRestaurantForm'),
);

const RestaurantLocation = lazy(() => import('../restaurant/location/RestuarantLocation'));
const RestaurantItem = lazy(() => import('../restaurant/location/RestaurantItem'));
const RestaurantTypes = lazy(() => import('../restaurant/location/RestaurantTypes'));
const RestaurantList = lazy(() => import('../restaurant/cuisine/RestaurantList'));
const UsdaSearch = lazy(() => import('../usda/UsdaSearch'));
const UsdaBrand = lazy(() => import('../usda/UsdaBrand'));
const UsdaBrandItems = lazy(() => import('../usda/UsdaBrandItems'));
const UsdaCategories = lazy(() => import('../usda/UsdaCategories'));

const UsdaBrandCategories = lazy(() => import('../usda/UsdaBrandCategories'));
const UsdaSearchFilteredItems = lazy(() => import('../usda/UsdaSearchFilteredItems'));
const UsdaElasticSearch = lazy(() => import('../usda/UsdaElasticSearch'));
const UsdaSearchResult = lazy(() => import('../usda/UsdaSearchResult'));
const UsdaElasticPredefineSearch = lazy(() => import('../usda/UsdaElasticPredefineSearch'));
const NutritionixSearch = lazy(() => import('../restaurant/cuisine/NutritionixSearch'));

const NutritionixRestaurants = lazy(() => import('../restaurant/location/NutritionixRestaurants'));
const StaffProfileEdit = lazy(() => import('../staffs/StaffProfileEdit'));
const Habit = lazy(() => import('../users/Habit'));
const HealthProfile = lazy(() => import('../usda/HealthProfile'));
const ModificationTip = lazy(() => import('../usda/ModificationTip'));
const ChatbotTest = lazy(() => import('../../pages/chatbotTest/ChatbotTest'));

const ChatbotProfile = lazy(() => import('../chatbot/ChatbotProfile'));
const MealLoggerList = lazy(() => import('../appContent/mealLogger/MealLoggerList'));
const MealLoggerEdit = lazy(() => import('../appContent/mealLogger/MealLoggerEdit'));
const MealLoggerAdd = lazy(() => import('../appContent/mealLogger/MealLoggerAdd'));

const CompanionImageAdd = lazy(() => import('../smartGoal/digitalCompanion/CompanionImageAdd'));
const DigitalCompanionAdd = lazy(() => import('../smartGoal/digitalCompanion/DigitalCompanionAdd'));
const DigitalCompanionEdit = lazy(() =>
  import('../smartGoal/digitalCompanion/DigitalCompanionEdit'),
);
const DigitalCompanionList = lazy(() =>
  import('../smartGoal/digitalCompanion/DigitalCompanionList'),
);
const SearchItem = lazy(() => import('../restaurant/search/SearchItem'));

const Orders = lazy(() => import('../restaurant/shoppingCart/Orders'));
const MenuPlanner = lazy(() => import('../restaurant/shoppingCart/Menus'));
const RestaurantItems = lazy(() => import('../restaurant/shoppingCart/RestaurantItems'));

const RestaurantSettings = lazy(() => import('../restaurant/shoppingCart/RestaurantSettings'));
const CompanionImageEdit = lazy(() => import('../smartGoal/digitalCompanion/CompanionImageEdit'));
const ShoppingCart = lazy(() => import('../users/shoppingcart'));
const CategoryCardList = lazy(() => import('../exerciseLogger/categoryCards/CategoryCardList'));
const CategoryCardEdit = lazy(() => import('../exerciseLogger/categoryCards/CategoryCardEdit'));
const CategoryCardAdd = lazy(() => import('../exerciseLogger/categoryCards/CategoryCardAdd'));

const BodyPartCardList = lazy(() => import('../exerciseLogger/bodyPartCards/BodyPartCardList'));
const BodyPartCardEdit = lazy(() => import('../exerciseLogger/bodyPartCards/BodyPartCardEdit'));
const BodyPartCardAdd = lazy(() => import('../exerciseLogger/bodyPartCards/BodyPartCardAdd'));
const ActivityCardAdd = lazy(() => import('../exerciseLogger/activityCards/ActivityCardAdd'));
const ActivityCardEdit = lazy(() => import('../exerciseLogger/activityCards/ActivityCardEdit'));
const ActivityCardList = lazy(() => import('../exerciseLogger/activityCards/ActivityCardList'));

const PopularExerciseCardAdd = lazy(() =>
  import('../exerciseLogger/popularExerciseCards/PopularExerciseCardAdd'),
);
const PopularExerciseCardEdit = lazy(() =>
  import('../exerciseLogger/popularExerciseCards/PopularExerciseCardEdit'),
);
const PopularExerciseCardList = lazy(() =>
  import('../exerciseLogger/popularExerciseCards/PopularExerciseCardList'),
);
const ExerciseDetailList = lazy(() =>
  import('../exerciseLogger/exerciseDetail/ExerciseDetailList'),
);
const ExerciseDetailEdit = lazy(() =>
  import('../exerciseLogger/exerciseDetail/ExerciseDetailEdit'),
);
const ExerciseDetailAdd = lazy(() => import('../exerciseLogger/exerciseDetail/ExerciseDetailAdd'));

const RestCuisineCategories = lazy(() =>
  import('../mealLogger/restaurant/cuisine/RestCuisineCategories'),
);
const RestCuisineCategoryAdd = lazy(() =>
  import('../mealLogger/restaurant/cuisine/RestCuisineCategoryAdd'),
);
const RestCuisineCategoryEdit = lazy(() =>
  import('../mealLogger/restaurant/cuisine/RestCuisineCategoryEdit'),
);
const Restaurants = lazy(() => import('../mealLogger/restaurant/cuisine/Restaurants'));
const ExerciseByEquipmentCardList = lazy(() =>
  import('../exerciseLogger/exerciseByEquipmentCards/ExerciseByEquipmentCardList'),
);
const ExerciseByEquipmentCardAdd = lazy(() =>
  import('../exerciseLogger/exerciseByEquipmentCards/ExerciseByEquipmentCardAdd'),
);
const ExerciseByEquipmentCardEdit = lazy(() =>
  import('../exerciseLogger/exerciseByEquipmentCards/ExerciseByEquipmentCardEdit'),
);

const HMCuisineCategories = lazy(() =>
  import('../mealLogger/homeMeal/cuisine/HMCuisineCategories'),
);
const HMCuisineCategoryAdd = lazy(() =>
  import('../mealLogger/homeMeal/cuisine/HMCuisineCategoryAdd'),
);
const HMCuisineCategoryEdit = lazy(() =>
  import('../mealLogger/homeMeal/cuisine/HMCuisineCategoryEdit'),
);
const HMFoodCategory = lazy(() => import('../mealLogger/homeMeal/category/HMFoodCategory'));

const TipsList = lazy(() => import('../exerciseLogger/tips/TipsList'));
const TipsEdit = lazy(() => import('../exerciseLogger/tips/TipsEdit'));
const TipsAdd = lazy(() => import('../exerciseLogger/tips/TipsAdd'));

const HMFoodCategoryAdd = lazy(() => import('../mealLogger/homeMeal/category/HMFoodCategoryAdd'));
const HMFoodCategoryEdit = lazy(() => import('../mealLogger/homeMeal/category/HMFoodCategoryEdit'));
const GuidesList = lazy(() => import('../exerciseLogger/guides/GuidesList'));
const GuidesEdit = lazy(() => import('../exerciseLogger/guides/GuidesEdit'));
const GuidesAdd = lazy(() => import('../exerciseLogger/guides/GuidesAdd'));
const LiveCoach = lazy(() => import('../livecoach/LiveCoach'));
const SmartGoalList = lazy(() => import('../livecoach/SmartGoalList'));
const LiveCoachDetail = lazy(() => import('../livecoach/LiveCoachDetail'));
const SmartGoalsForm = lazy(() => import('../livecoach/SmartGoalsForm'));
const IngredientSearch = lazy(() => import('../ingredientSearch/ingredientSearch'));
const UserDetails = lazy(() => import('../users/UserDetails'));
const RecipeAnalysis = lazy(() => import('../recipeAnalysis/RecipeAnalysis'));
const GenericFoodTree = lazy(() => import('../genericFoodTree/GenericFoodTree'));
const Spoonacular = lazy(() => import('../spoonacular/Spoonacular'));
const LocationDetailAdd = lazy(() => import('../restaurant/cuisine/LocationDetailAdd'));
const LocationDetailEdit = lazy(() => import('../restaurant/cuisine/LocationDetailEdit'));
const UserCreatedRecipe = lazy(() => import('../userRecipe/UserCreatedRecipe'));
const EducationModule = lazy(() => import('../educationModule/EducationModule'));
const DatasetImporter = lazy(() => import('../datasetImporter/DatasetImporter'));
const CodeGenerator = lazy(() => import('../codeGenerator/CodeGenerator'));
const RiskSurveyResult = lazy(() => import('../riskSurveyResult/RiskSurveyResult'));

const SuspendedView = ({ children }) => (
  <Suspense fallback={<div>loading</div>}>{children}</Suspense>
);

function Navigation() {
  return (
    <>
      <SuspendedView>
        <Route path={`/`} exact component={Dashboard} />
      </SuspendedView>
      <SuspendedView>
        <Route path={`/dashboard/`} exact component={Dashboard} />
      </SuspendedView>
      <SuspendedView>
        <Route path={`/staffs/`} exact component={Staffs} />
      </SuspendedView>
      <SuspendedView>
        <Route path={`/staffs/:id`} exact component={EditStaff} />
      </SuspendedView>

      <SuspendedView>
        <Route path={`/users/`} exact component={Users} />
      </SuspendedView>
      <SuspendedView>
        <Route path={`/user/new/`} exact component={UserAdd} />
      </SuspendedView>
      <SuspendedView>
        <Route path={`/users/:id`} exact component={UserView} />
      </SuspendedView>
      <SuspendedView>
        <Route path={`/users/edit/:id`} exact component={UserEdit} />
      </SuspendedView>

      <SuspendedView>
        <Route path={`/smartgoals/`} exact component={SmartGoalsLookup} />
      </SuspendedView>
      <SuspendedView>
        <Route path={`/smartgoals/lookups/`} exact component={SmartGoalsLookup} />
      </SuspendedView>
      <SuspendedView>
        <Route path={`/smartgoals/lookup/new/`} exact component={SmartGoalsLookupAdd} />
      </SuspendedView>
      <SuspendedView>
        <Route path={`/smartgoals/lookups/:id`} exact component={SmartGoalsLookupEdit} />
      </SuspendedView>

      <SuspendedView>
        <Route path={`/smartgoals/goals/`} exact component={SmartGoals} />
      </SuspendedView>
      <SuspendedView>
        <Route path={`/smartgoals/goal/new/`} exact component={SmartGoalAdd} />
      </SuspendedView>
      <SuspendedView>
        <Route path={`/smartgoals/goals/:id`} exact component={SmartGoalEdit} />
      </SuspendedView>
      <SuspendedView>
        <Route path={`/smartgoals/taskLookups/`} exact component={DailyTasksLookup} />
      </SuspendedView>
      <SuspendedView>
        <Route path={`/smartgoals/taskLookup/new/`} exact component={DailyTasksLookupAdd} />
      </SuspendedView>
      <SuspendedView>
        <Route path={`/smartgoals/taskLookups/:id`} exact component={DailyTasksLookupEdit} />
      </SuspendedView>

      <SuspendedView>
        <Route path={`/smartgoals/dailyTasks/`} exact component={DailyTasks} />
      </SuspendedView>
      <SuspendedView>
        <Route path={`/smartgoals/dailyTask/new/`} exact component={DailyTaskAdd} />
      </SuspendedView>
      <SuspendedView>
        <Route path={`/smartgoals/dailyTasks/:id`} exact component={DailyTaskEdit} />
      </SuspendedView>

      <SuspendedView>
        <Route path={`/smartgoals/tasks/`} exact component={Tasks} />
      </SuspendedView>
      <SuspendedView>
        <Route path={`/smartgoals/task/new/`} exact component={TaskAdd} />
      </SuspendedView>
      <SuspendedView>
        <Route path={`/smartgoals/tasks/:id`} exact component={TaskEdit} />
      </SuspendedView>

      <SuspendedView>
        <Route path={`/smartgoals/chatbots/`} exact component={Chatbots} />
      </SuspendedView>
      <SuspendedView>
        <Route path={`/smartgoals/chatbot/new/`} exact component={ChatbotAdd} />
      </SuspendedView>
      <SuspendedView>
        <Route path={`/smartgoals/chatbots/:id`} exact component={ChatbotEdit} />
      </SuspendedView>

      <SuspendedView>
        <Route path={`/smartgoals/media/`} exact component={Medias} />
      </SuspendedView>
      <SuspendedView>
        <Route path={`/smartgoals/media_/new/`} exact component={MediaAdd} />
      </SuspendedView>
      <SuspendedView>
        <Route path={`/smartgoals/media/:id`} exact component={MediaEdit} />
      </SuspendedView>

      <SuspendedView>
        <Route path={`/grocery/storeTypes/`} exact component={StoreTypes} />
      </SuspendedView>
      <SuspendedView>
        <Route path={`/grocery/storeType/new/`} exact component={StoreTypeAdd} />
      </SuspendedView>
      <SuspendedView>
        <Route path={`/grocery/storeTypes/:id`} exact component={StoreTypeEdit} />
      </SuspendedView>

      <SuspendedView>
        <Route path={`/homeMeal/homeSnacks/`} exact component={HomeSnacks} />
      </SuspendedView>
      <SuspendedView>
        <Route path={`/homeMeal/homeSnack/:id`} exact component={HomeSnackForm} />
      </SuspendedView>

      <SuspendedView>
        <Route path={`/restaurant/cuisines/`} exact component={CuisineCategories} />
      </SuspendedView>
      <SuspendedView>
        <Route path={`/restaurant/cuisine/new/`} exact component={CuisineCategoryAdd} />
      </SuspendedView>
      <SuspendedView>
        <Route path={`/restaurant/cuisines/:id`} exact component={CuisineCategoryEdit} />
      </SuspendedView>
      <SuspendedView>
        <Route path={`/restaurant/cuisineMain/:id`} exact component={CuisineMain} />
      </SuspendedView>

      <SuspendedView>
        <Route path={`/homeMeal/recipes/:id`} exact component={HomeMealRecipes} />
      </SuspendedView>
      {/* <Route
        path={`/homeMeal/recipe/:type/new`}
        exact
        component={HomeMealRecipeAdd}
      /> */}
      <SuspendedView>
        <Route path={`/homeMeal/recipe/:id`} exact component={HomeMealRecipeForm} />
      </SuspendedView>

      <SuspendedView>
        <Route
          path={`/homeMeal/foodList/`}
          exact
          render={(props) => <HomeMealFoodList {...props} foodType={FOOD_TYPES.FOOD} />}
        />
      </SuspendedView>

      <SuspendedView>
        <Route
          path={`/homeMeal/recipes/`}
          exact
          render={(props) => <HomeMealFoodList {...props} foodType={FOOD_TYPES.RECIPE} />}
        />
      </SuspendedView>

      <SuspendedView>
        <Route
          path={`/homeMeal/unpublished/`}
          exact
          render={(props) => <HomeMealFoodList {...props} foodType={FOOD_TYPES.UNPUBLISHED} />}
        />
      </SuspendedView>

      <SuspendedView>
        <Route path={`/homeMeal/foods/:id`} exact component={HomeMealFoods} />
      </SuspendedView>
      <SuspendedView>
        <Route path={`/homeMeal/food/:id`} exact component={HomeMealFoodForm} />
      </SuspendedView>

      <SuspendedView>
        <Route path={`/homeMeal/foodCategory/`} exact component={HomeMealFoodCategory} />
      </SuspendedView>
      <SuspendedView>
        <Route
          path={`/restaurant/snackShopRestaurant/:id`}
          exact
          component={SnackShopRestaurantForm}
        />
      </SuspendedView>

      <SuspendedView>
        <Route path={`/restaurant/cuisineRestaurants/:id`} exact component={CuisineRestaurants} />
      </SuspendedView>
      <SuspendedView>
        <Route
          path={`/restaurant/cuisineRestaurants/:type/new`}
          exact
          component={CuisineRestaurantAdd}
        />
      </SuspendedView>

      <SuspendedView>
        <Route
          path={`/restaurant/cuisineRestaurants/:type/:id`}
          exact
          component={CuisineRestaurantEdit}
        />
      </SuspendedView>

      <SuspendedView>
        <Route path={`/restaurant/restaurantMenu/`} exact component={RestaurantMenu} />
      </SuspendedView>

      <SuspendedView>
        <Route path={`/restaurant/menuItem/:id`} exact component={MenuItem} />
      </SuspendedView>

      <SuspendedView>
        <Route path={`/restaurant/menuAddonItem/:id`} exact component={MenuAddonItem} />
      </SuspendedView>

      <SuspendedView>
        <Route path={`/restaurant/menuDishType/`} exact component={MenuDishType} />
      </SuspendedView>

      <SuspendedView>
        <Route path={`/restaurant/restaurants/:id`} exact component={RestaurantForm} />
      </SuspendedView>

      <SuspendedView>
        <Route
          path={`/restaurant/snackShopRestaurants/:id`}
          exact
          component={SnackShopRestaurants}
        />
      </SuspendedView>

      <SuspendedView>
        <Route path={`/restaurant/snackShops/`} exact component={SnackShops} />
      </SuspendedView>
      <SuspendedView>
        <Route path={`/restaurant/snackShop/new/`} exact component={SnackShopAdd} />
      </SuspendedView>

      <SuspendedView>
        <Route path={`/restaurant/snackShops/:id`} exact component={SnackShopEdit} />
      </SuspendedView>

      <SuspendedView>
        <Route path={`/restaurant/nutritionixSearch/`} exact component={NutritionixSearch} />
      </SuspendedView>

      <SuspendedView>
        <Route path={`/restaurant/location/`} exact component={RestaurantLocation} />
      </SuspendedView>

      <SuspendedView>
        <Route path={`/restaurant/list`} exact component={RestaurantList} />
      </SuspendedView>
      <SuspendedView>
        <Route path={`/restaurant/list/:id`} exact component={RestaurantItem} />
      </SuspendedView>

      <SuspendedView>
        <Route path={`/restaurant/types/`} exact component={RestaurantTypes} />
      </SuspendedView>

      <SuspendedView>
        <Route path={`/usda/search/`} exact component={UsdaSearch} />
      </SuspendedView>
      <SuspendedView>
        <Route path={`/usda/fullsearch/`} exact component={UsdaElasticSearch} />
      </SuspendedView>

      <SuspendedView>
        <Route path={`/usda/predefinesearch/`} exact component={UsdaElasticPredefineSearch} />
      </SuspendedView>

      <SuspendedView>
        <Route path={`/usda/brands/`} exact component={UsdaBrand} />
      </SuspendedView>
      <SuspendedView>
        <Route path={`/usda/brands/:name`} exact component={UsdaBrandItems} />
      </SuspendedView>

      <SuspendedView>
        <Route path={`/usda/brandcategories/:name`} exact component={UsdaBrandCategories} />
      </SuspendedView>

      <SuspendedView>
        <Route path={`/usda/searchitems/`} exact component={UsdaSearchFilteredItems} />
      </SuspendedView>

      <SuspendedView>
        <Route path={`/usda/categories/`} exact component={UsdaCategories} />
      </SuspendedView>

      <SuspendedView>
        <Route path={`/usda/searchResult/`} exact component={UsdaSearchResult} />
      </SuspendedView>

      <SuspendedView>
        <Route path={`/appContent/mealLoggerList/`} exact component={MealLoggerList} />
      </SuspendedView>
      <SuspendedView>
        <Route path={`/appContent/mealLoggerList/:id`} exact component={MealLoggerEdit} />
      </SuspendedView>
      <SuspendedView>
        <Route path={`/appContent/mealLoggerAdd/`} exact component={MealLoggerAdd} />
      </SuspendedView>

      <SuspendedView>
        <Route path={`/exerciseLogger/categoryCardList/`} exact component={CategoryCardList} />
      </SuspendedView>
      <SuspendedView>
        <Route path={`/exerciseLogger/categoryCardList/:id`} exact component={CategoryCardEdit} />
      </SuspendedView>
      <SuspendedView>
        <Route path={`/exerciseLogger/categoryCardAdd/`} exact component={CategoryCardAdd} />
      </SuspendedView>
      <SuspendedView>
        <Route path={`/exerciseLogger/bodyPartCardList/`} exact component={BodyPartCardList} />
      </SuspendedView>
      <SuspendedView>
        <Route path={`/exerciseLogger/bodyPartCardList/:id`} exact component={BodyPartCardEdit} />
      </SuspendedView>
      <SuspendedView>
        <Route path={`/exerciseLogger/bodyPartCardAdd/`} exact component={BodyPartCardAdd} />
      </SuspendedView>
      <SuspendedView>
        <Route
          path={`/exerciseLogger/popularExerciseCardList/`}
          exact
          component={PopularExerciseCardList}
        />
      </SuspendedView>
      <SuspendedView>
        <Route
          path={`/exerciseLogger/popularExerciseCardList/:id`}
          exact
          component={PopularExerciseCardEdit}
        />
      </SuspendedView>
      <SuspendedView>
        <Route
          path={`/exerciseLogger/popularExerciseCardAdd/`}
          exact
          component={PopularExerciseCardAdd}
        />
      </SuspendedView>
      <SuspendedView>
        <Route path={`/exerciseLogger/activityCardList/`} exact component={ActivityCardList} />
      </SuspendedView>
      <SuspendedView>
        <Route path={`/exerciseLogger/activityCardList/:id`} exact component={ActivityCardEdit} />
      </SuspendedView>
      <SuspendedView>
        <Route path={`/exerciseLogger/activityCardAdd/`} exact component={ActivityCardAdd} />
      </SuspendedView>
      <SuspendedView>
        <Route path={`/exerciseLogger/exerciseDetailList/`} exact component={ExerciseDetailList} />
      </SuspendedView>
      <SuspendedView>
        <Route
          path={`/exerciseLogger/exerciseDetailList/:id`}
          exact
          component={ExerciseDetailEdit}
        />
      </SuspendedView>
      <SuspendedView>
        <Route path={`/exerciseLogger/exerciseDetailAdd/`} exact component={ExerciseDetailAdd} />
      </SuspendedView>
      <SuspendedView>
        <Route
          path={`/exerciseLogger/exerciseByEquipmentList/`}
          exact
          component={ExerciseByEquipmentCardList}
        />
      </SuspendedView>
      <SuspendedView>
        <Route
          path={`/exerciseLogger/exerciseByEquipmentList/:id`}
          exact
          component={ExerciseByEquipmentCardEdit}
        />
      </SuspendedView>
      <SuspendedView>
        <Route
          path={`/exerciseLogger/exerciseByEquipmentAdd/`}
          exact
          component={ExerciseByEquipmentCardAdd}
        />
      </SuspendedView>
      <SuspendedView>
        <Route path={`/exerciseLogger/tipsList/`} exact component={TipsList} />
      </SuspendedView>
      <SuspendedView>
        <Route path={`/exerciseLogger/tipsList/:id`} exact component={TipsEdit} />
      </SuspendedView>
      <SuspendedView>
        {' '}
        <Route path={`/exerciseLogger/tipsAdd/`} exact component={TipsAdd} />
      </SuspendedView>
      <SuspendedView>
        <Route path={`/exerciseLogger/guidesList/`} exact component={GuidesList} />
      </SuspendedView>
      <SuspendedView>
        <Route path={`/exerciseLogger/guidesList/:id`} exact component={GuidesEdit} />
      </SuspendedView>
      <SuspendedView>
        <Route path={`/exerciseLogger/guidesAdd/`} exact component={GuidesAdd} />
      </SuspendedView>

      <SuspendedView>
        <Route path={`/mealLogger/modificationTip/`} exact component={ModificationTip} />
      </SuspendedView>
      <SuspendedView>
        <Route path={`/mealLogger/genericFoodTree/`} exact component={GenericFoodTree} />
      </SuspendedView>
      <SuspendedView>
        <Route path={`/mealLogger/restaurant/cuisine`} exact component={RestCuisineCategories} />
      </SuspendedView>
      <SuspendedView>
        <Route
          path={`/mealLogger/restaurant/cuisine/new/`}
          exact
          component={RestCuisineCategoryAdd}
        />
      </SuspendedView>
      <SuspendedView>
        <Route
          path={`/mealLogger/restaurant/cuisines/:id`}
          exact
          component={RestCuisineCategoryEdit}
        />
      </SuspendedView>
      <SuspendedView>
        <Route
          path={`/mealLogger/restaurant/cuisineRestaurants/:id`}
          exact
          component={Restaurants}
        />
      </SuspendedView>

      <SuspendedView>
        <Route path={`/mealLogger/homeMeal/cuisine`} exact component={HMCuisineCategories} />
      </SuspendedView>
      <SuspendedView>
        <Route path={`/mealLogger/homeMeal/cuisine/new/`} exact component={HMCuisineCategoryAdd} />
      </SuspendedView>
      <SuspendedView>
        <Route path={`/mealLogger/homeMeal/cuisines/:id`} exact component={HMCuisineCategoryEdit} />
      </SuspendedView>
      <SuspendedView>
        <Route path={`/mealLogger/homeMeal/category`} exact component={HMFoodCategory} />
      </SuspendedView>
      <SuspendedView>
        <Route path={`/mealLogger/homeMeal/addcategory/new/`} exact component={HMFoodCategoryAdd} />
      </SuspendedView>

      <SuspendedView>
        <Route path={`/mealLogger/homeMeal/category/:id`} exact component={HMFoodCategoryEdit} />
      </SuspendedView>

      <SuspendedView>
        <Route path={`/restaurant/:id/locations/:id`} exact component={LocationDetailEdit} />
      </SuspendedView>
      <SuspendedView>
        <Route path={`/restaurant/:id/locationAdd/`} exact component={LocationDetailAdd} />
      </SuspendedView>
      <SuspendedView>
        <Route
          path={`/restaurant/nutritionixRestaurants/`}
          exact
          component={NutritionixRestaurants}
        />
      </SuspendedView>
      <SuspendedView>
        <Route path={`/foodItemSearch`} exact component={SearchItem} />
      </SuspendedView>
      <SuspendedView>
        <Route path={`/profile/`} exact component={StaffProfileEdit} />
      </SuspendedView>
      <SuspendedView>
        <Route path={`/usda/healthProfile/`} exact component={HealthProfile} />
      </SuspendedView>
      <SuspendedView>
        <Route path={`/smartgoals/digitalCompanions/`} exact component={DigitalCompanionList} />
      </SuspendedView>
      <SuspendedView>
        <Route path={`/smartgoals/digitalCompanion/new`} exact component={DigitalCompanionAdd} />
      </SuspendedView>
      <SuspendedView>
        <Route path={`/smartgoals/digitalCompanions/:id`} exact component={DigitalCompanionEdit} />
      </SuspendedView>
      <SuspendedView>
        <Route
          path={`/smartgoals/digitalCompanions/:id/companionImageEdit/:compImgId`}
          exact
          component={CompanionImageEdit}
        />
      </SuspendedView>
      <SuspendedView>
        <Route
          path={`/smartgoals/digitalCompanions/:id/companionImage/new`}
          exact
          component={CompanionImageAdd}
        />
      </SuspendedView>
      <SuspendedView>
        <Route path={`/habit/`} exact component={Habit} />
      </SuspendedView>
      <SuspendedView>
        <Route path={`/userDetails/`} exact component={UserDetails} />
      </SuspendedView>
      <SuspendedView>
        <Route path={`/chatbotTest/`} exact component={ChatbotTest} />
      </SuspendedView>
      <SuspendedView>
        <Route path={`/chatbot/chatbotProfile/`} exact component={ChatbotProfile} />
      </SuspendedView>
      <SuspendedView>
        <Route path={`/shoppingcart/:restaurantid/items/`} exact component={RestaurantItems} />
      </SuspendedView>
      <SuspendedView>
        <Route path={`/shoppingcart/:restaurantid/menus/`} exact component={MenuPlanner} />
      </SuspendedView>
      <SuspendedView>
        <Route path={`/shoppingcart/:restaurantid/orders/`} exact component={Orders} />
      </SuspendedView>
      <SuspendedView>
        <Route
          path={`/shoppingcart/:restaurantid/settings/`}
          exact
          component={RestaurantSettings}
        />
      </SuspendedView>
      <SuspendedView>
        <Route path={`/shoppingcart/`} exact component={ShoppingCart} />
      </SuspendedView>
      <SuspendedView>
        <Route path={`/livecoach/`} exact component={LiveCoach} />
      </SuspendedView>
      <SuspendedView>
        <Route path={`/livecoach/smartGoals/:username`} exact component={SmartGoalList} />
      </SuspendedView>
      <SuspendedView>
        <Route path={`/livecoach/:username`} exact component={LiveCoachDetail} />
      </SuspendedView>
      <SuspendedView>
        <Route path={`/livecoach/assignSmartGoals/:username`} exact component={SmartGoalsForm} />
      </SuspendedView>
      <SuspendedView>
        <Route path={`/ingredient-search/`} exact component={IngredientSearch} />
      </SuspendedView>
      <SuspendedView>
        <Route path={`/recipe-analysis/`} exact component={RecipeAnalysis} />
      </SuspendedView>
      <SuspendedView>
        <Route path="/spoonacular" exact component={Spoonacular} />
      </SuspendedView>
      <SuspendedView>
        <Route path="/userCreatedRecipes/" exact component={UserCreatedRecipe} />
      </SuspendedView>
      <SuspendedView>
        <Route path="/education-module/" exact component={EducationModule} />
      </SuspendedView>
      <SuspendedView>
        <Route path="/dataset-importer/" exact component={DatasetImporter} />
      </SuspendedView>
      <SuspendedView>
        <Route path="/code-generator/" exact component={CodeGenerator} />
      </SuspendedView>
      <SuspendedView>
        <Route path="/risk-survey-results/" exact component={RiskSurveyResult} />
      </SuspendedView>
    </>
  );
}
export default Navigation;
