class BridgeManager {
  /**
   * @param {unkown} message
   */
  postMessage(message) {
    window?.webkit?.messageHandlers?.submit?.postMessage(JSON.stringify(message));
  }

  /**
   * @param {unkown} message
   */
  postErrorMessage(message) {
    window?.webkit?.messageHandlers?.error?.postMessage(message);
  }
}

export default new BridgeManager();
