import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import '@aws-amplify/ui/dist/style.css';
import Amplify from 'aws-amplify';
import aws_exports from './aws-exports';
import { Provider } from 'react-redux';
import { store } from './state';

import App from './components/App';
import './index.css';

Amplify.configure(aws_exports);
ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
