import _ from 'lodash';

/**
 * @param string} str
 * @returns {boolean}
 */
function isCamelCase(str) {
  // Regular expression to check for camel case
  const camelCaseRegex = /^[a-z]+([A-Z][a-z]*)*$/;
  return camelCaseRegex.test(str);
}

/**
 * @param string} str
 * @returns {boolean}
 */
function isSnakeCase(str) {
  // Regular expression to check for snake case
  const snakeCaseRegex = /^[a-z]+(_[a-z]+)*$/;
  return snakeCaseRegex.test(str);
}

/**
 * @param {any} obj
 * @returns {any}
 */
export const snakeCase = (obj) =>
  _.transform(obj, (acc, value, key, target) => {
    const formattedKey = _.isArray(target) || !isCamelCase(key) ? key : _.snakeCase(key);
    acc[formattedKey] = _.isObject(value) ? snakeCase(value) : value;
  });

/**
 * @param {any} obj
 * @returns {any}
 */
export const camelCase = (obj) =>
  _.transform(obj, (acc, value, key, target) => {
    const formattedKey = _.isArray(target) || !isSnakeCase(key) ? key : _.camelCase(key);
    acc[formattedKey] = _.isObject(value) ? camelCase(value) : value;
  });
